import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <section id="alta-container" className="html">
    <div id="Altaredwoodcontainer">
          <header className="App-header">
            <div class="Altaredwoodh1 App-title">Alta Redwood</div>
            <div className="Altaredwoodh2 App-description">
              Wellness and Technology Services
            </div>
          </header>
          <div class="Partnersection">
            <div class="Partnerdiv">
              <h3 className="partnerh1">
                <a class="Altaredwooda" href="https://jessmckeown.com">
                  
                </a>
                <a class="dm" href="https://jessmckeown.com">Jess McKeown</a> <a class="Altaredwooda" href="https://jessmckeown.com/#schedule-embed">
                  📅
                </a>
              </h3>
              <span className="av">
                <a class="Altaredwooda" href="https://jessmckeown.com">
                  <img class="Altaredwoodimg"
                    src="https://altaredwood.work/static/img/JessicaMcKeown_Headshot-Edit-2-4d2839e9-1600w.jpg"
                    alt="jess"
                    id="jdc-img"
                  />
                </a>
              </span>
            </div>

            <div class="Partnerdiv">
              <h3 className="partnerh1">
                <a class="dm" href="https://danmckeown.info">Dan McKeown</a> <a class="Altaredwooda" href="https://pacificio.com">
                   💻
                </a>
              </h3>
              <span className="av">
                <a class="Altaredwooda" href="https://pacificio.com">
                  <img class="Altaredwoodimadjusted"
                    src="./img/DJM-June7th-2024__1400w.jpg"
                    alt="dan"
                    id="djm-img"
                  />
                </a>
              </span>
            </div>
          </div>
          {/* <footer className="link" id="alta-link-footer">
            copyright 2020 <a href="https://altaredwood.work">altaredwood.work</a>
          </footer> */}
        </div>
    </section>
    {/* <Link to="/page-2/">Go to page 2</Link> <br />
    <Link to="/using-typescript/">Go to "Using TypeScript"</Link> */}
  </Layout>
)

export default IndexPage
